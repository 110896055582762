import React, { FC } from 'react';
import { graphql, PageProps } from 'gatsby';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import Layout from 'components/Layout';
import Banner from 'components/Banner';

import { parseBanner } from 'components/Banner/parsers';
import './lds-page.scss';

interface ILdsPage extends PageProps {
  pageContext: { breadcrumbs: UmbracoTypes.IBreadCrumb[] };
  data: LdsTypes.ILds;
}

const LdsPage: FC<ILdsPage> = ({
  pageContext: { breadcrumbs },
  data: {
    page: {
      nodes: [
        {
          ldsBanner,
          body,
          seoMetaTitle,
          seoMetaDescription,
          seoMetaKeywords,
          seoExternalHreflangs,
          seoContentType,
          lang,
        },
      ],
    },
    header,
    footer,
    siteSettings,
    covidBanner,
    newsletterPopupDefault,
    newsletterPopupDiscount,
    newsletterPopupFreeDelivery,
    disclaimerPopup,
    searchPath,
    brandSettings,
    legalPopup,
    pageLangs: { nodes: langs },
  },
}) => (
  <Layout
    seo={{
      seoMetaTitle,
      seoMetaDescription,
      seoMetaKeywords,
      seoExternalHreflangs,
      seoContentType,
    }}
    header={header}
    footer={footer}
    siteSettings={siteSettings}
    covidBanner={covidBanner}
    newsletterPopupDefault={newsletterPopupDefault}
    newsletterPopupDiscount={newsletterPopupDiscount}
    newsletterPopupFreeDelivery={newsletterPopupFreeDelivery}
    disclaimerPopup={disclaimerPopup}
    searchPath={searchPath}
    brandSettings={brandSettings}
    legalPopup={legalPopup}
    langSettings={{
      currentLang: lang,
      langs,
    }}
  >
    <div className="lds-page">
      <Banner
        {...parseBanner(ldsBanner?.[0]?.properties)}
        breadcrumbs={breadcrumbs}
        bgBannerColor={
          ldsBanner?.[0]?.properties?.pageBannerBannerBgColor?.[0]?.properties?.colorPicker
            ?.label || 'coral'
        }
      />
      <div className="lds-content">
        <Container fluid>
          <Row>
            <Col lg={11}>
              {body ? (
                <DangerouslySetInnerHtml
                  html={body.replace(new RegExp('^(<p><b>(.*?)</b></p><p>&nbsp;</p>)'), '')}
                />
              ) : null}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  </Layout>
);

export const query = graphql`
  query($lang: String!, $link: String, $pageBannerImageSize: Int = 1000, $pageIdRegex: String) {
    page: allLdsPage(filter: { link: { eq: $link }, lang: { eq: $lang } }) {
      nodes {
        lang
        ...FragmentSeo
        ldsBanner {
          properties {
            ...FragmentBanner
          }
        }
        body
      }
    }
    pageLangs: allLdsPage(filter: { umbracoId: { regex: $pageIdRegex } }) {
      nodes {
        link
        lang
      }
    }
    ...FragmentCommonCompositions
  }
`;

export default LdsPage;
